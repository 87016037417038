<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="filter.search"
        append-icon="mdi-magnify"
        label="Search name, email or phone number"
        single-line
        hide-details
        @input="search"
      ></v-text-field>
    </v-card-title>
    <div
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="isLoading"
      infinite-scroll-distance="100"
      height="550px"
    >
      <v-data-table
        :headers="headers"
        :items="users"
        multi-sort
        hide-default-footer
        disable-pagination
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        @update:sort-desc="sortTable"
        class="mx-5"
      >
        <template
          v-slot:body="{ items: users }"
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="isLoading"
          infinite-scroll-distance="100"
        >
          <tr
            v-for="user in users"
            :key="user.id"
            class="clickable"
            @click="
              $router.push({
                name: 'user.details',
                params: { id: user.id }
              })
            "
            height="75px"
          >
            <td>
              <user-avatar
                :avatar="user.avatar"
                size="40"
                class="mr-12"
              ></user-avatar>
            </td>
            <td>{{ user.first_name }}</td>
            <td>{{ user.last_name }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.email }}</td>
            <td>{{ user.raw_phone_number }}</td>
            <td>{{ user.addedDate }}</td>
            <td style="text-align: center;">{{ user.impact_points }}</td>
          </tr>
        </template>
      </v-data-table>
    </div>
  </v-card>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll'
import { mapActions, mapMutations, mapState } from 'vuex'
import UserAvatar from '@/components/UserAvatar'
import debounce from 'lodash/debounce'

export default {
  name: 'OrganisationUsers',

  directives: {
    infiniteScroll
  },

  components: {
    UserAvatar
  },

  data() {
    return {
      sortBy: [],
      sortDesc: [],
      isLoading: false,
      filter: {
        search: ''
      },
      headers: [
        { sortable: false, width: '5%' },
        {
          text: 'First name',
          value: 'first_name',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Last name',
          value: 'last_name',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Email',
          value: 'email',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Phone number',
          value: 'phone_number',
          sortable: false,
          width: '15%'
        },
        {
          text: 'Added date',
          value: 'addedDate',
          sortable: true,
          width: '15%'
        },
        {
          text: 'Impact points',
          value: 'impact_points',
          sortable: true,
          width: '5%'
        }
      ]
    }
  },

  mounted() {
    this.clearUsers()
    this.fetchUsers()
  },

  computed: {
    ...mapState({
      organisation: state => state.organisation.organisationDetails,
      users: state => state.user.list,
      listMeta: state => state.user.listMeta
    })
  },

  methods: {
    ...mapActions({
      getUsers: 'user/getUsers'
    }),

    ...mapMutations({
      clearUsers: 'user/clearUserList'
    }),

    async fetchUsers(page = 1) {
      if (!this.isLoading) {
        let params = { page, organisation: this.organisation.id }

        if (this.sortBy) {
          let sort = this.sortBy.map(function(sort, index) {
            if (sort === 'addedDate') {
              sort = 'created_at'
            }

            if (this[index] === true) {
              return '-' + sort
            }

            return sort
          }, this.sortDesc)

          params.sort = sort
        }

        if (this.filter.search) {
          params.search = this.filter.search
        }

        this.isLoading = true
        await this.getUsers(params)
        this.isLoading = false
      }
    },

    loadMore() {
      if (this.listMeta.current_page < this.listMeta.last_page) {
        this.fetchUsers(this.listMeta.current_page + 1)
      }
    },

    async sortTable() {
      let params = { page: 1, organisation: this.organisation.id }

      if (this.sortBy) {
        let sort = this.sortBy.map(function(sort, index) {
          if (sort === 'addedDate') {
            sort = 'created_at'
          }

          if (this[index] === true) {
            return '-' + sort
          }

          return sort
        }, this.sortDesc)

        params.sort = sort
      }

      if (this.filter.search) {
        params.search = this.filter.search
      }

      this.clearUsers()

      this.isLoading = true
      await this.getUsers(params)
      this.isLoading = false
    },

    search: debounce(function() {
      this.clearUsers()
      this.fetchUsers()
    }, 600)
  }
}
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0 1em;
}
</style>
