<template>
  <div v-if="form" style="max-width: 420px">
    <v-container>
      <v-form ref="form">
        <v-row no-gutters>
          <UploadLogo
            :image-source="organisation ? organisation.logo : null"
            @change="onChangeImage"
          />
          <div
            class="ml-5 v-messages theme--light error--text"
            v-if="form.$getError('logo')"
          >
            {{ form.$getError('logo')[0] }}
          </div>
        </v-row>
        <v-row no-gutters class="pt-4">
          <v-col>
            <label class="text-field-label">Name</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.name"
              :error-messages="form.$getError('name')"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <label class="text-field-label">Bio</label>
            <v-textarea
              no-resize
              flat
              solo
              class="mt-2"
              rows="3"
              v-model="form.bio"
              :error-messages="form.$getError('bio')"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <label class="text-field-label">Industry</label>
            <v-text-field
              flat
              solo
              class="mt-2"
              v-model="form.industry"
              :error-messages="form.$getError('industry')"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <label class="text-field-label">Website URL</label>
            <v-text-field
              flat
              solo
              id="website-field"
              class="mt-2 website-field"
              prefix="https://"
              v-model="form.website_url"
              :error-messages="form.$getError('website_url')"
            />
          </v-col>
        </v-row>

        <div class="mt-4 d-flex justify-space-between">
          <v-btn
            color="yellow"
            depressed
            class="px-6"
            height="40px"
            @click="submit"
            :loading="form.$busy"
            >Save</v-btn
          >
        </div>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Compressor from 'compressorjs'
import Form from '@/utils/form'
import UploadLogo from './UploadLogo'

export default {
  name: 'OrganisationForm',

  components: {
    UploadLogo,
  },

  data() {
    return {
      isLoading: false,
      imageChanged: false,
      showCropperModal: false,
      form: null,
    }
  },

  computed: {
    ...mapState({
      organisation: (state) => state.organisation.organisationDetails,
      user: (state) => state.auth.user,
    }),

    isOrgManager() {
      return this.user.role === 'org_manager'
    },
  },

  methods: {
    ...mapActions({
      getOrganisationDetails: 'organisation/getOrganisationDetails',
      createOrganisation: 'organisation/createOrganisation',
      updateOrganisation: 'organisation/updateOrganisation',
      deleteOrganisation: 'organisation/deleteOrganisation',
    }),

    ...mapMutations({
      clearOrganisationDetails: 'organisation/clearOrganisationDetails',
      callSnackbar: 'callSnackbar',
    }),

    async getOrganisation() {
      this.isLoading = true
      await this.getOrganisationDetails(this.$route.params.id)
      this.form = new Form(this.organisation)
      this.form.website_url = this.form.website_url.replace(/(^\w+:|^)\/\//, '')
      this.isLoading = false
    },

    async submit() {
      this.form.$busy = true
      this.form.$clearErrors()

      if (!this.organisation) {
        await this.createOrganisation(this.getFormData())
          .then(() => {
            this.form.$busy = false
            this.imageChanged = false
            this.callSnackbar({
              state: true,
              text: 'Successfully added new organisation',
              color: 'green',
            })
            this.$router.replace({
              name: 'organisations',
            })
          })
          .catch((err) => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      } else {
        let formData = this.getFormData()

        formData.append('_method', 'put')

        await this.updateOrganisation(formData)
          .then(() => {
            this.form.$busy = false
            this.imageChanged = false
            this.callSnackbar({
              state: true,
              text: 'Successfully update organisation',
              color: 'green',
            })

            if (!this.isOrgManager) {
              this.$router.replace({
                name: 'organisations',
              })
            }
          })
          .catch((err) => {
            this.form.$busy = false
            if (err.response.status === 422) {
              this.form.$setErrors(err.response.data.errors)
            }
          })
      }
    },

    getFormData() {
      let data = new FormData()

      if (this.imageChanged) {
        data.append('logo', this.form.logo)
      }

      if (this.form.name !== '' && this.form.name !== null) {
        data.append('name', this.form.name)
      }

      if (this.form.bio !== '' && this.form.bio !== null) {
        data.append('bio', this.form.bio)
      }

      if (this.form.industry !== '' && this.form.industry !== null) {
        data.append('industry', this.form.industry)
      }

      if (this.form.website_url !== '' && this.form.website_url !== null) {
        data.append('website_url', 'https://'.concat(this.form.website_url))
      }

      return data
    },

    onChangeImage(blob) {
      this.imageChanged = true
      this.form.$clearErrors()

      if (blob.size < 4096) {
        this.form.logo = blob
      } else if (blob.size < 1000000) {
        let _this = this
        // eslint-disable-next-line
        new Compressor(blob, {
          quality: 0.2,
          success(result) {
            _this.form.logo = result
          },
        })
      } else {
        this.form.$setErrors({ logo: ['Image size is too large'] })
      }
    },
  },

  created() {
    if (!this.isOrgManager) {
      if (this.$route.params.id) {
        this.getOrganisation()
      } else {
        this.form = new Form({
          name: '',
          bio: '',
          logo: '',
          industry: '',
          website_url: '',
        })
      }
    } else {
      this.form = new Form(this.organisation)
      this.form.website_url = this.form.website_url.replace(/(^\w+:|^)\/\//, '')
    }
  },

  watch: {
    $route() {
      if (!this.isOrgManager) {
        this.getOrganisation()
      } else {
        this.form = new Form(this.organisation)
        this.form.website_url = this.form.website_url.replace(
          /(^\w+:|^)\/\//,
          ''
        )
      }
    },
  },
}
</script>

<style>
.website-field div.v-text-field__prefix {
  padding: 0 !important;
}

input#website-field {
  padding: 8px 0 !important;
}
</style>
